import logo from './logo.svg'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import CryptoWarfareUnveiled from './CryptoWarfareUnveiled/Index'
import NewPLM from './New/NewPLM'
import { HelmetProvider } from 'react-helmet-async'

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<NewPLM />} path='/' />
          <Route element={<NewPLM />} path='/multimea-vida-din-tv' />
          <Route
            element={<CryptoWarfareUnveiled />}
            path='/crypto-warfare-unveiled'
          />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  )
}

export default App
